import React from 'react';
import Layout from "../../components/layout";

const Cottesloe = () => {
    return (
        <Layout>
            <h1>Cottesloe</h1>
            <h2>Instructors</h2>
            <p><strong>Sensei Terry Lyon</strong><br/>
                <strong>Email:</strong> <a href="mailto:tlyon@bigpond.net.au">tlyon@bigpond.net.au</a><br/>
                <strong>Website:</strong> <a href="http://www.lyon-karate.com">www.lyon-karate.com</a><br/>
                <strong>Social:</strong> <a
                    href="https://www.facebook.com/pages/Lyon-Karate-Traditional-Okinawan-Goju-Ryu/1010874132257208">Facebook</a><br/>
                <strong>Mob:</strong> 0408 907 312</p>

            <p><strong>Class Times</strong><br/>
                <strong>Monday and Wednesday:</strong> 6.15 - 7.30pm</p>

            <p><strong>Location</strong><br/>
                St Mary's Star of the Sea Church Hall<br/>
                Cnr of Stirling Hwy and McNeil st, Cottesloe, Perth WA</p>

            <p>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3383.774957696127!2d115.76565550000001!3d-31.99411550000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32a423ae052967%3A0xe859ca575e3dd6e6!2sStirling+Hwy+%26+McNeil+St%2C+Peppermint+Grove+WA+6011!5e0!3m2!1sen!2sau!4v1427617059568"
                    width="400" height="300" frameborder="0" title="cottesloe"></iframe>
            </p>
        </Layout>

    )
}

export default Cottesloe;
